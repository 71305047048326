<script
    setup
    lang='ts'
    generic='
        R extends Record<string, any>,
        O extends Array<string> | Array<R>,
        F extends O extends Array<R> ? UnionKeys<R> : never
    '
>

import type { VNodeRef } from 'vue'

const props = withDefaults(defineProps<{
    name: string
    options: O
    field?: F
    displayField?: F
    autocomplete?: boolean
    showOptionsOn?: 'focus' | 'input'
    tabindex?: `${number}`
    tooltip?: Array<string>
}>(), {
    field: undefined,
    displayField: undefined,
    tabindex: '0',
    showOptionsOn: undefined,
    tooltip: undefined
})

const model = defineModel<string>()

const emit = defineEmits<{
    mount: []
    update: [string]
    clear: []
}>()

defineExpose({
    clear() {
        model.value = undefined
        selectedIndex.value = undefined

        if (inputRef.value) {
            inputRef.value.value = query.value = ''
        }

        emit('clear')
    }
})

const inputRef = ref<HTMLInputElement | null>(null)

const { focused: inputFocused } = useFocus(inputRef)

const query = ref(model.value || '')
const selectedIndex = ref<number>()
const forceHideOptions = ref(false)

const filteredIndices = computed<Array<number>>(() => {
    return props.options.reduce((acc, _, index) => {
        if (getDisplayValue(index).toLowerCase().includes(query.value.toLowerCase())) {
            acc.push(index)
        }

        return acc
    }, [] as Array<number>)
})

const { list, containerProps, wrapperProps } = useVirtualList(filteredIndices, { itemHeight: 40 })

function getValue(
    option: O[number],
    _field: Extract<keyof typeof props, 'field' | 'displayField'>
) {
    if (typeof option === 'string') {
        return option
    }

    const field = props[_field] || props.field

    if (field && isRecord(option)) {
        if (field in option) {
            return option[field]
        }

        return getValueByPath(option, field.split('.')) as string
    }

    return JSON.stringify(option)
}

function getDisplayValue(index: number): string {
    return getValue(props.options[index], 'displayField') || ''
}

function handleSelect(index: number) {
    const modelValue = getValue(props.options[index], 'field')

    model.value = modelValue
    selectedIndex.value = index

    if (inputRef.value) {
        inputRef.value.value = query.value = getDisplayValue(index)
        inputRef.value.blur()
    }

    if (modelValue) {
        emit('update', modelValue)

        if (props.showOptionsOn === 'focus') {
            forceHideOptions.value = true
        }
    }
}

const assignInputToRef: VNodeRef = ref => {
    if (ref) {
        if (hasKey(ref, '$el')) {
            inputRef.value = ref.$el
        }
        else {
            inputRef.value = ref as HTMLInputElement
        }

        emit('mount')
    }
    else {
        inputRef.value = null
    }
}

</script>

<template>
    <HeadlessCombobox
        :id='name'
        v-slot='{ open: internalOpenState }'
        :default-value='undefined'
        as='div'
        class='relative'
        @update:model-value='handleSelect'
    >
        <ShadcnTooltipProvider
            disable-closing-trigger
            :delay-duration='0'
        >
            <ShadcnTooltip :disabled='!tooltip'>
                <ShadcnTooltipTrigger as-child>
                    <HeadlessComboboxInput
                        :id='`${name}-input`'
                        :ref='assignInputToRef'
                        data-grace-area-trigger
                        class='w-full rounded border-2 px-3 py-2 leading-tight text-gray-700 focus:border-blue-700 focus:outline-none'
                        :display-value='index => selectedIndex !== undefined && typeof index === "number" ? getDisplayValue(index) : ""'
                        :autocomplete='autocomplete ? "on" : "off"'
                        :tabindex='tabindex'
                        @change='query = $event.target.value'
                        @blur='query = selectedIndex !== undefined ? getDisplayValue(selectedIndex) : ""'
                        @click='forceHideOptions = false'
                    />
                </ShadcnTooltipTrigger>
                <ShadcnTooltipContent
                    :id='`${name}-tooltips`'
                    as='div'
                    class='bg-white'
                    side='right'
                >
                    <p
                        v-for='tooltip_ in tooltip'
                        :key='tooltip_'
                    >
                        {{ tooltip_ }}
                    </p>
                </ShadcnTooltipContent>
            </ShadcnTooltip>
        </ShadcnTooltipProvider>

        <div
            v-if='(showOptionsOn === "focus" ? inputFocused && !forceHideOptions : internalOpenState)'
            v-bind='containerProps'
            class='absolute z-50 mt-1 max-h-72 min-h-12 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none'
        >
            <HeadlessComboboxOptions
                v-bind='wrapperProps'
                :static='showOptionsOn === "focus"'
                style='width: max-content;'
                class='min-w-full'
            >
                <HeadlessComboboxOption
                    v-for='{ data: index } in list'
                    :id='`${name}-option-${index + 1}`'
                    :key='index'
                    v-slot='{ active }'
                    class='h-10'
                    :value='index'
                    :disabled='index === selectedIndex'
                >
                    <p
                        class='w-full whitespace-nowrap px-4 py-2 transition-colors'
                        :class='{
                            "cursor-pointer": index !== selectedIndex,
                            "hover:bg-blue-400 hover:text-white": !active && index !== selectedIndex,
                            "text-white": active || index === selectedIndex,
                            "bg-blue-400": active && index !== selectedIndex,
                            "pointer-events-none bg-blue-500": index === selectedIndex
                        }'
                    >
                        {{ getDisplayValue(index) }}
                    </p>
                </HeadlessComboboxOption>

                <HeadlessComboboxOption
                    v-if='!list.length'
                    :id='`${name}-no-data`'
                    disabled
                    class='px-4 py-2 text-gray-400'
                >
                    {{ $t('general_terms.no_data') }}
                </HeadlessComboboxOption>
            </HeadlessComboboxOptions>
        </div>
    </HeadlessCombobox>
</template>
