export function getValueByPath<
    T,
    R extends Record<string, T>,
    P extends SubPaths<R>
>(record: R, path: P | Array<string>) {
    const [key, ...nestedKeys] = Array.isArray(path) ? path : path.split('.')

    if (key in record) {
        const maybeRecord = record[key]

        if (isRecord(maybeRecord)) {
            return getValueByPath(maybeRecord, nestedKeys)
        }

        return maybeRecord
    }

    return <const>`invalid path: ${key}`
}
